<template>
  <v-app>
   <v-app-bar
      app
      color="primary"
    >
    <!--class="shrink mt-1 hidden-sm-and-down"-->
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          contain
          min-width="150"
          :src="require('@/assets/img/foilsportslogodsmall.png')"
          width="150"
          @click="$router.push('/')"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn small to="/contact" class='ml-2'>
        Contact
      </v-btn>
      <v-btn small to="/location" class='ml-1'>
        Location
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer
        color="primary"
        padless
      >
        <v-row
          justify="center"
          no-gutters
        >
          <FooterIcons/>
          <v-col
            class="primary lighten-1 py-4 text-center white--text"
            cols="12"
          >

            &#169; {{ new Date().getFullYear() }} — <strong>Foil Sports Curacao — Caracasbaaiweg 447, Curacao — <a class='white--text' href='tel://+5999-7380883'>+5999-7380883</a></strong>
          </v-col>
        </v-row>
      </v-footer>   
  </v-app>
</template>

<script>

import GlobalHelper from "@/mixins/GlobalHelper"
import FooterIcons from "@/components/FooterIcons"

export default {
  mixins:[GlobalHelper],
  name: 'App',

  components: {
    FooterIcons
  },
  data: () => ({
    
  })
};
</script>
