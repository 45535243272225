// define a mixin object
import axios from 'axios'
import Qs from 'qs'

const GlobalHelper = {
  data(){
    return {
      stdRules:{
        required: v => !!v || "This field is required",
        email: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ],
        notneg: v => parseInt(v) > 0 || "This field is required"
      },
      config:{
        apiURL:  process.env.NODE_ENV == "development"? "http://localhost/foilsports/public/wscposconnect.php" : "wscposconnect.php",
        formURL:  process.env.NODE_ENV == "development"? "http://localhost/foilsports/public/contactform.php" : "contactform.php"
      },
      activities:{
        basicLesson:{
          description:"Introduction behind the boat",
          products:[
            {
              price:"70",
              pax:3,
              duration:"1:15"
            },            
            {
              price:"80",
              pax:2,
              duration:"1:00"
            },
            {
              price:"120",
              pax:1,
              duration:"0:45"
            },
          ]
        },
        wakeFoiling:{
          description:"Wake foiling",
          products:[
            {
              price:"70",
              pax:3,
              duration:"1:15"
            },            
            {
              price:"80",
              pax:2,
              duration:"1:00"
            },
            {
              price:"120",
              pax:1,
              duration:"0:45"
            },
          ]
        },
        supFoiling:{
          description:"SUP Foiling",
          products:[ 
            {
              price:"150",
              pax:1,
              duration:"1:30"
            },
          ]
        },
        wingFoiling:{
          description:"Wing Foiling",
          products:[ 
            {
              price:"115",
              pax:3,
              duration:"2:30"
            }, 
            {
              price:"129",
              pax:2,
              duration:"2:00"
            }, 
            {
              price:"172",
              pax:1,
              duration:"2:00"
            },
          ]
        },
        windFoiling:{
          description:"Wind Foiling",
          products:[ 
            {
              price:"80",
              pax:2,
              duration:"1:30"
            }, 
            {
              price:"100",
              pax:1,
              duration:"1:00"
            },
          ]
        }

      },
      footerLinks:[
        { name:'mail',
          icon:'mdi-mail',
          link:'/contact'
        },
        {
          name:'whatsapp',
          icon:'mdi-whatsapp',
          href:'https://wa.link/lbov9z'
        },
        { name:'facebook',
          icon:'mdi-facebook',
          href:'https://facebook.com/foilsportscuracao'
        },
        { name:'instagram',
          icon:'mdi-instagram',
          href:'https://instagram.com/foilsportscuracao'
        },
        { name:'telephone',
          icon:'mdi-phone',
          href:'tel://+5999-7380883'
        }
      ]
    }
  },
  methods: {
    sendToServer(payload,){
      return new Promise((resolve,reject)=>{
        axios.post(this.config.formURL,Qs.stringify(payload)).then(response => {
          if(!response.data.result){
            if(response.data.data){
              reject("error: " + response.data.data)
            }else{
              reject("error: " + response.data)
            }
          }else{
            resolve(response.data.data)
          }
        }).catch(err => {
            reject('Something went wrong:' + err)
        })
      })
    }
  },
  created(){
  }
}
export default GlobalHelper
