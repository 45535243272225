<template>
  <div>
      <v-btn 
            v-for="(footer,i) in footerLinks" :key="`footer_${i}`"
            icon
            class="my-2"
            @click="clickFooter(footer)"
          >
          <v-icon>{{footer.icon}}</v-icon>
      </v-btn>  
  </div>
</template>

<script>
import GlobalHelper from "@/mixins/GlobalHelper"
export default {
  mixins:[GlobalHelper],
  name: 'App', 
  methods:{
    clickFooter(footer){
      if(footer.link){
        this.$router.push(footer.link);
      }else{
        window.location.href = footer.href;
      }
    }
  }  
}
</script>