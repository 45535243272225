<template>
      <v-card color="accent" @click="activity=='wingFoiling'? toExternal('https://wingfoilcuracao.com/boeknu') :bookNow()">
        <v-card-title class='pb-1 justify-center'>
          Starting at
        </v-card-title>
        <v-card-text class='pb-0 text-h4 text-center'>
          ${{minPrice}}
        </v-card-text>
        <v-card-actions class='pt-0 justify-center'>  
          <v-btn v-if="this.activity=='wingFoiling'" href="https://wingfoilcuracao.com/boeknu" small class='mt-2' color='primary'>Book now</v-btn>        
          <v-btn v-else @click="bookNow()" small class='mt-2' color='primary'>Book now</v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import GlobalHelper from "@/mixins/GlobalHelper"
export default {
  props:{
    activity:{
      required:true
    }
  },
  mixins:[GlobalHelper],
  methods:{
    toExternal(page){
      window.location.href = page
    },
    bookNow(){
      let activity = this.activity
      this.$router.push({name:"BookNow",params:{activity}})
    }
  },
  computed:{
    minPrice(){
      return this.activities[this.activity].products[0].price
    },
    description(){
      return this.activities[this.activity].description
    }
  }
}
</script>