<template>
  <v-container>
        <v-container>
          <v-layout
           class='justify-center'
            wrap
            row
          >

    <v-flex xs12 sm12 lg5 class="mr-3 mb-3" >
      <v-card>
        <v-card-title>Wingfoiling lessons and rental Curacao!</v-card-title>
        <v-card-text>
          <v-img class='mb-2'
            :src="require('../assets/img/wing1.jpg')"
            :lazy-src="require('../assets/img/wing1lazy.jpg')"
          />
          <div style="
            position:absolute;
            top:12%;
            left:10%;
            width:200px;
            z-index:1;
          ">
              <span class="font-weight-black text-h4 white--text">Joing the winged revolution</span>
        </div>
        <v-row>
          <v-col md="8">
            Winging is the new rage sweeping over the wind powered seascape.
            Direct power and controll like a windsurfer, but without the restrictions of a mast. The ease of pumping a kite, but without the hassle of the lines,
            complicated safety systems and harnessed power. Being on a foil, water friction is minimal and wing sizes are generally small and light. 
          </v-col>
          <v-col>
            <BookNow activity="wingFoiling"/>
          </v-col>
        </v-row>
         </v-card-text>
        <!--<BookNow activity="Introduction behind the boat"/>-->
      </v-card>
    </v-flex>          
    <v-flex xs12 sm12 lg3 class="mr-3 mb-3" >
      <v-card height="100%">
        <v-card-title>Wakefoil surfing</v-card-title>
        <v-card-text>
              <video
                width="100%"
                  autoplay muted loop :poster="require('../assets/img/wakesurfcov.png')">
                <source :src="require('../assets/video/wakefoil.mp4')" type='video/mp4'>
              </video>
          Once you've mastered stabalizing your flight, pick a section of the wake and let go of the rope!
              Surf an endless wave in Curacao!
          <v-row class='justify-center'>
            <span width='50%'>
              <BookNow activity="wakeFoiling"/>
            </span>  
        </v-row>    
        </v-card-text>
      </v-card>
    </v-flex>      
          <v-flex xs12 sm12 lg3 class="mr-3 mb-3" >
            <v-card height="100%">
              <v-card-title>SUP Foiling</v-card-title>
              <v-card-text>
                <v-img
                 :src="require('../assets/img/ingmarsup.jpg')"
                 class='mb-2'
                />
                Imagine rolling ocean swells. Waves that aren't even breaking. No imagine flying across the face,
                connecting one swell to the other. SUP foiling is not for the faint of hearth. You need to build paddlepower
                before you catch your first wave. But the steep learning curve is well worth it. Not ready for SUP foiling?
                Take a SUP tour or a SUP class  with <a href='http://supcuracao.com'>SUP Curacao</a>                 
                <v-row class='justify-center'>
                  <span width='50%'>
                    <BookNow activity="supFoiling"/>
                  </span>  
                </v-row>  
              </v-card-text>
            </v-card>
          </v-flex>
        <v-flex xs12 sm12 lg5 class="mr-3 mb-3" >
            <v-card height="100%">
              <v-card-title>Wing Foiling</v-card-title>
              <v-card-text>
                <v-img
                 class="mb-2"
                 :src="require('../assets/img/wing.jpg')"
                  :lazy-src="require('../assets/img/winglazy.jpg')"
                />
                <v-row>
                  <v-col md="8">
                      What do you get if you mix a kite and a windsurf sail? A wing! Wingsurfing is the newest craze on the water.
                      Wingsurfing started out as something to do with less wind. Now with different wingsizes and small boards we're seeing 
                      speeds and jumps we never thought possible a year ago. With a little experience in sailing, windsurfing or kiting 
                      this sport is pretty easy to master. We have the right setup to get you started! 
                  </v-col>
                  <v-col>  
                    <BookNow activity="wingFoiling"/>
                  </v-col>
                </v-row>              
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 lg3 class="mr-3 mb-3" >
            <v-card height="100%">
              <v-card-title>Wind foiling</v-card-title>
              <v-card-text>
                    <video
                      width="100%"
                        autoplay muted loop :poster="require('../assets/img/windfoilcov.png')">
                      <source :src="require('../assets/video/windfoil.mp4')" type='video/mp4'>
                    </video>
                    Forget the days sitting on the beach waiting for wind to windsurf! With a wind foil you are planing without formula size gear!
                    If you already know how to windsurf, learning how to foil is easy! We'll get you flying step by step! If you can't windsurf yet, learn windsurfing today at <a href='https://windsurfingcuracao.com'>Windsurfing Curacao</a>     
                  <v-row class='justify-center'>
                      <span width='50%'>  
                        <BookNow activity="windFoiling"/>
                      </span>  
                  </v-row>    
              </v-card-text>              
            </v-card>
          </v-flex>                       
          </v-layout>
        </v-container>


<!--
<span style="position: fixed;
background: rgba(0, 0, 0, 0.5);
color: #f1f1f1;
width: 100%;
padding: 20px;">test 123</span>
-->

  </v-container>
</template>
<style scoped>
.container { position:relative; }
.container video {
    position:relative;
    z-index:0;
}
.overlay {
    position:absolute;
    top:20%;
    left:15%;
    z-index:1;
}
</style>
<script>
import BookNow from "@/components/BookNow"
export default {
  name: 'MainContent',
  components:{BookNow},
  data: () => ({
  }),
};
</script>
